/* eslint-disable indent */
import React, { ReactNode, useContext } from "react"
import { AUTH_KEY, AUTH_TYPE_KEY } from "../constants/localStorageKeys"
import { getUserFromToken } from "../utils/getUserFromToken"
import { updateDefaultHeader } from '../utils/http'
import { getADUserFromToken } from "../utils/getADUserFromToken"
import { AuthType } from "../constants/authTypes"

export interface UserResponse {
  displayName: string,
  token: string,
  roles: Array<string>,
}

export interface User extends Omit<UserResponse, 'token'> {
  token: string | null,
}

export interface UserContextState {
  userState: {
    user: User
  },
  userReducer: {
    setUser: (value: User | ((prev: User) => User)) => void,
    resetUser: () => void
  }
}

const UserContext = React.createContext<UserContextState | undefined>(undefined)

export function UserProvider({ children }: {children : ReactNode}) {
  const token = localStorage.getItem(AUTH_KEY)
  const authType = localStorage.getItem(AUTH_TYPE_KEY)

  if (token) {
    updateDefaultHeader('Authorization', `Bearer ${token}`)
  }

  let initialUser: User = getDefaultUser()

  if (authType && token) {
    switch ((AuthType as any)[authType]) {
      case AuthType.ActiveDirectory:
        initialUser = getADUserFromToken(token)
        break
      case AuthType.Identity:
        initialUser = getUserFromToken(token) ?? initialUser
        break
    }
  }

  const [user, setUser] = React.useState<User>(initialUser)
  const resetUser = () => setUser(getDefaultUser())

  const state: UserContextState = React.useMemo(() => {
    return {
      userState: {
        user
      },
      userReducer: {
        setUser,
        resetUser
      }
    }
  }, [user])

  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  )
}

function getDefaultUser(): User {
  return {
    displayName: "",
    token: null,
    roles: []
  } as User
}

export function useUserContext() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}
