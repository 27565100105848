import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import "./SidebarModal.scss"

export interface ISidebarModalProps {
  show: boolean
  children: Array<ReactNode>
}

export default function SidebarModal ({ show, children }: ISidebarModalProps) {
  const anchorElement = document.getElementsByClassName('page-container')[0]

  return (
    show && anchorElement ?
      ReactDOM.createPortal(
        <div className='sidebar-modal place-content-center' id="modal-root">
          <div className='sidebar-modal-body'>
            {children}
          </div>
        </div>, anchorElement
      ) : null
  )
}
