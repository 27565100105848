import React, { useEffect, useMemo, useState } from 'react'
import ArrowIcon from '../Icons/ArrowIcon'
import './DatePicker.scss'
import { buildMonthWeeks, isToday } from './datePickerUtils'

export interface DatePickerDate {
  date: Date,
  isUsable: boolean,
}

export interface IDatePickerProps {
  value: Date,
  onChange: (date: Date) => void
}

export default function DatePicker({ value, onChange }: IDatePickerProps) {
  const displayMonths = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']
  const displayWeekDays = ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö']
  const [localDate, setLocalDate] = useState(value)
  const weeks = useMemo(() => buildMonthWeeks(localDate), [localDate])

  const changeMonth = (direction: number) => {
    const date = new Date(localDate)
    const currentMonth = date.getMonth()
    date.setMonth(currentMonth + direction)
    setLocalDate(date)
  }

  const changeDay = (newDate: Date | null) => {
    if(newDate !== null) { 
      onChange(newDate)
    }
  }

  const dayClassNames =  (datePickerDate: DatePickerDate | null) => {
    if(datePickerDate == null || datePickerDate.date == null) {
      return ''
    }
    
    let classes: string = 'date-picker__day'
    if(isToday(datePickerDate.date)) {
      classes += ' date-picker__day-today'
    }
    if(!datePickerDate.isUsable){
      classes += ' date-picker__day-not-usable'
    }
    if(datePickerDate.date.getFullYear() === value.getFullYear() &&
      datePickerDate.date.getMonth() === value.getMonth() &&
      datePickerDate.date.getDate() === value.getDate()){
      classes += ' date-picker__day-selected'
    }

    return classes
  }

  useEffect(() => {
    if(value) {
      setLocalDate(value)
    }
  }, [value])

  return (
    <div className='date-picker'>
      <div className='date-picker__months'>
        <button className='button button-secondary button-icon' onClick={() =>changeMonth(-1)}>
          <ArrowIcon direction='left' />
        </button>
        {displayMonths[localDate.getMonth()]} {localDate.getFullYear()}
        <button className='button button-secondary button-icon' onClick={() =>changeMonth(1)}>
          <ArrowIcon direction='right' />
        </button>
      </div>
      <div className='date-picker__days'>
        <table>
          <thead>
            <tr>
              {displayWeekDays.map((day, i) =>
                <th key={i}>
                  {day}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {weeks.map((weekDays, i) => 
              <tr key={i}>
                {weekDays.map((datePickerDate, ix) =>
                  <td key={ix} onClick={datePickerDate?.isUsable ? () => changeDay(datePickerDate.date) : undefined}>
                    <div className={dayClassNames(datePickerDate)}>
                      {datePickerDate?.date ? datePickerDate.date.getDate() : ''}
                    </div>
                  </td>                
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
