import React, { useEffect, useState } from 'react'
import config from '../../config'
import { post } from '../../utils/http'
import { SessionBrief } from '../../types/SessionBrief'
import { Link, useParams } from 'react-router-dom'
import SpinnerIcon from '../Icons/SpinnerIcon'
import Filter from '../SessionFilter/SessionFilter'
import { dateStringToSessionDisplayDate, differenceInMinutes } from '../../utils/dateUtils'
import displayPrice from '../../utils/displayPrice'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import CloseIcon from '../Icons/CloseIcon'
import UserIcon from '../Icons/UserIcon'
import sessionStateStyle from '../../utils/sessionStateStyle'
import SessionAccessDeniedInfo from '../SessionAccessDeniedInfo/SessionAccessDeniedInfo'
import AcrFlag from '../AcrFlag/AcrFlag'
import { getFilterPayload, useFilterContext } from '../../contexts/filter-context'

export default function SessionsList() {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const { filterState } = useFilterContext()
  const [sessions, setSessions] = useState<Array<SessionBrief>>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchSessions = async () => {
      if (!filterState.filter?.storeId) {
        return
      }
      else if (filterState.filter.from > filterState.filter.to) {
        return
      }
      
      setLoading(true)
      setError(false)
      setSessions([])
      try {
        const filterPayload = getFilterPayload(filterState.filter)
        const result = await post<typeof filterPayload, Array<SessionBrief>>(`${config.STORE_API_BASE_URL}/v2/session/`, filterPayload)
        const sortedBydate = result.sort((a, b) => {
          return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime()
        })
        setLoading(false)
        setSessions(sortedBydate)
      } catch (error) {
        setLoading(false)
        setError(true)
      }
    }
    fetchSessions()
  }, [filterState.filter])

  const onModalClose = () => {
    navigate('/sessions')
  }

  return (
    <div className='sessions'>
      <Filter />
      <div className="list">
        {loading &&
          <div className='grid place-content-center'>
            <SpinnerIcon />
          </div>
        }
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        {sessions.map((session, i) => {
          return (
            <div className={`list-item ${sessionStateStyle(session)}`} key={i}>
              <Link to={`/sessions/${session.id}`} className='list-item__left-cell'>
                <div className='list-item__order'>
                  <div className='flex items-center'>#{session.id}{session.acr && <div className='ml-3'><AcrFlag acr={session.acr} width={18}/></div>}</div>
                  <div>{dateStringToSessionDisplayDate(session.firstActivity)} - {dateStringToSessionDisplayDate(session.latestActivity)} ({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</div>
                </div>
                <div className='list-item__purchase'>{session.itemCount} varor{session.paymentInternalDisplayName ? ', ' + session.paymentInternalDisplayName : ''}</div>
                <div className='list-item__right flex'>
                  {session.accessDeniedReason && <div className='flex pb-4 md:pb-0'><SessionAccessDeniedInfo accessDeniedReason={session.accessDeniedReason } /></div>}
                  <div className='list-item__price'>{displayPrice(session.totalPrice)} kr</div>                  
                </div>
              </Link>
              {sessionId && sessionId === session.id &&
                <Modal show={!!sessionId} onClose={onModalClose}>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='justify-self-start'>
                      <Link to='/sessions' className='icon-text'><CloseIcon />Stäng</Link>
                    </div>
                    {session.customerId &&
                    <div className='justify-self-end'>
                      <Link to={`/customers/${session.customerId}/${session.acr}`} state={{ previousUrl: `${location.pathname}${location.search}` }} className='icon-text'>Kund <UserIcon /></Link>
                    </div>
                    }
                  </div>
                  <SessionInfo />
                </Modal>
              }
            </div>
          )
        })}
        {!loading && !error && sessions.length === 0 && <div className='grid place-items-center'>Inget resultat hittades för din sökning.</div>}
      </div>
    </div >
  )
}

