import React, { useEffect, useState, Suspense } from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { OverviewData } from '../../types/OverviewData'
import { SessionBrief } from '../../types/SessionBrief'
import { get, post } from '../../utils/http'
import Card from '../Card/Card'
import SpinnerIcon from '../Icons/SpinnerIcon'
import TopOrderItems from '../TopOrderItems/TopOrderItems'
import './OverviewStatistics.scss'

const Chart = React.lazy(() => import('../Chart/Chart'))

export function OverviewStatistics() {
  let intervall: ReturnType<typeof setInterval>
  const { storeState } = useStoreContext()
  const [overview, setOverview] = useState<OverviewData | null>(null)
  const [todaySessions, setTodaySessions] = useState<Array<SessionBrief> | null> ()
  const [loading, setLoading] = useState(false)

  const startIntervall = () => {
    intervall = setInterval(() => {
      fetchOverviewData()
    }, 10000)
  }

  const fetchOverviewData = async () => {
    setLoading(true)
    const startTime = new Date(new Date().setHours(0,0,0,0)).toISOString()
    const endTime = new Date().toISOString()
    const body = {
      from:  startTime,
      to: endTime,
      onlySuspicious: false
    }

    const promises = await Promise.allSettled([
      get<OverviewData>(`${config.STORE_API_BASE_URL}/v1/monitor/overview/${storeState.selectedStore?.id}?startTime=${startTime}&endTime=${endTime}`),
      post<typeof body, Array<SessionBrief>>(`${config.STORE_API_BASE_URL}/v1/monitor/sessions/${storeState.selectedStore?.id}`, body)
    ])

    if(promises[0].status === 'fulfilled') {
      setOverview(promises[0].value)
    }
    if(promises[1].status === 'fulfilled') {
      setTodaySessions(promises[1].value)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (storeState.selectedStore?.id) {
      fetchOverviewData()
      if (intervall) {
        clearInterval(intervall)
      }
      startIntervall()
    }
    return () => {
      clearInterval(intervall)
    }
  }, [storeState.selectedStore])

  return (
    <div>
      <>
        {overview &&
          <div className='overview-statistics-container grid grid-cols-2 md:grid-cols-5'>
            <Link to="/live-feed" className='overview-statistics-cell overview-statistics-cell__visitors'>
              <div>
                <div className='text-5xl font-extrabold'>{overview.visitors}</div>
                <h5>I butiken</h5>
              </div>
            </Link>
            <Link to="/live-feed"  className='overview-statistics-cell overview-statistics-cell__shopping'>
              <div>
                <div className='text-5xl font-extrabold'>{overview.shopping}</div>
                <h5>Handlar</h5>
              </div>
            </Link>
            <Link to="/live-feed"  className='overview-statistics-cell  overview-statistics-cell__paying'>
              <div>
                <div className='text-5xl font-extrabold'>{overview.paying}</div>
                <h5>Betalar</h5>
              </div>
            </Link>
            <Link to="/sessions" className='overview-statistics-cell overview-statistics-cell__completed-purchases'>
              <div>
                <div  className='text-5xl font-extrabold'>{overview.completedPurchases}</div>
                <h5>Genomförda köp</h5>
              </div>
            </Link>
            <Link to="/sessions?onlySuspicious=true" className='overview-statistics-cell  overview-statistics-cell__suspiscious-purchases'>
              <div>
                <div  className='text-5xl font-extrabold'>{overview.suspiciousSessions}</div>
                <h5>Misstänkta kundvarv</h5>
              </div>
            </Link>
          </div>
        }
        <div className='grid grid-rows-2 lg:grid-cols-5 gap-3'>
          {todaySessions && 
            <div className='lg:col-span-2'>
              <TopOrderItems sessions={todaySessions}/>
            </div>
          }
          {overview && overview.hourlyStatistics && 
          <div className='lg:col-span-3'>
            <Card>
              <>
                <h5 className='mt-0'>Aktivitet</h5>
                <div className="overview-statistics-chart-wrap">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart hourlyStatistics={overview?.hourlyStatistics} />
                  </Suspense>
                </div>
              </>
            </Card>
          </div>
          }
        </div>
      </>

      <div className='grid place-content-center'>
        {loading && <SpinnerIcon />}
      </div>
    </div>
  )
}
