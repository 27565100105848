import React from 'react'
import RequireAuth from './RequireAuth'
import PageContainer from './PageContainer/PageContainer'
import { StoreProvider } from '../contexts/store-context'
import { FilterProvider } from '../contexts/filter-context'

function AuthenticatedPage({ children }: { children: JSX.Element }) {
  return (
    <RequireAuth>
      <StoreProvider>
        <FilterProvider>
          <PageContainer>
            {children}
          </PageContainer>
        </FilterProvider>
      </StoreProvider>
    </RequireAuth>
  )
}

export default AuthenticatedPage