import React from "react"
import { useStoreContext } from "../contexts/store-context"

const SelectStore = () => {
  const { storeState, storeReducer } = useStoreContext()

  const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const newSelectedStore = storeState.availableStores.find((store) => store.id === value) ?? null
    if (newSelectedStore) {
      storeReducer.setSelectedStore(newSelectedStore)
    }
  }

  return (
    <>
      {storeState && storeState.selectedStore &&
        <select onChange={onChange} defaultValue={storeState.selectedStore.id} className="select-header">
          {storeState.availableStores.map((store) =>
            <option key={store.id} value={store.id}>
              {store.name}
            </option>
          )}
        </select>
      }
    </>
  )
}

export default SelectStore