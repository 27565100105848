import React, { ReactNode } from "react"
import { STORE_ID_KEY } from "../constants/localStorageKeys"

export interface Store {
 id: string,
 name: string
}

type SelectedStoreReducerType = (value: (Store | null) | ((prev: (Store | null)) => (Store | null))) => void
type AvailableStoresReducerType = (value: Store[] | ((prev: Store[]) => Store[])) => void

export interface StoreContextState {
  storeState: {
    selectedStore: Store | null,
    availableStores: Store[]
  },
  storeReducer: {
    setSelectedStore: SelectedStoreReducerType,
    setAvailableStores: AvailableStoresReducerType
  }
}

const StoreContext = React.createContext<StoreContextState | undefined>(undefined)

export function StoreProvider({ children }: {children : ReactNode}) {
  const [selectedStore, setSelectedStore] = React.useState<Store | null>(null)
  const [availableStores, setAvailableStores] = React.useState<Store[]>([])
  
  React.useMemo(() => {
    if(selectedStore) {
      localStorage.setItem(STORE_ID_KEY, selectedStore.id)
    }
  }, [selectedStore])

  const state: StoreContextState = React.useMemo(() => {
    return {
      storeState: {
        selectedStore,
        availableStores
      },
      storeReducer: {
        setSelectedStore,
        setAvailableStores
      }
    }
  }, [selectedStore, availableStores])

  return (
    <StoreContext.Provider value={state}>
      {children}
    </StoreContext.Provider>
  )
}

export function useStoreContext () {
  const context = React.useContext(StoreContext)
  if (!context) {
    throw new Error('useStoreContext must be used within a StoreProvider')
  }
  return context
}
