import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { SessionDetails } from '../../types/SessionDetails'
import { dateStringToDisplayDate, dateStringToSessionDisplayDate, differenceInMinutes } from '../../utils/dateUtils'
import displayPrice from '../../utils/displayPrice'
import { get } from '../../utils/http'
import SpinnerIcon from '../Icons/SpinnerIcon'
import "./SessionInfo.scss"
import AcrFlag from '../AcrFlag/AcrFlag'


export default function SessionInfo() {
  const { storeState } = useStoreContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [session, setSession] = useState<SessionDetails | null>(null)
  const { sessionId } = useParams()

  const fetchSession = async () => {
    try {
      const result = await get<SessionDetails>(`${config.STORE_API_BASE_URL}/v1/monitor/session/${storeState.selectedStore?.id}/${sessionId}`)
      const sortedEvents = result.events.sort((eventA, eventB) => {
        return new Date(eventA.createdAt).getTime() - new Date(eventB.createdAt).getTime()
      })
      setLoading(false)
      setSession({ ...result, events: sortedEvents })
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const eventTypeTranslation =  {
    AbortConfirmed: 'Kundvarv avslutat',
    AbortPressedWithItemsInCart: 'Kund vill avbryta - Artiklar i varukorg',
    AbortPressedWithoutItemsInCart: 'Kund vill avbryta',
    AddToCart : 'Lägg till i varukorg',
    AgeAccessDenied: 'Identifiering (BankID)',
    CreateCart: 'Skapa varukorg',
    DoorAccessDenied: 'Öppna dörr',
    EnterDoor : 'Öppna dörr',
    ExitDoor : 'Dörr ut',
    InitPayment : 'Start betalning',
    NotStammisAccessDenied: 'Identifiering (BankID)',
    PaymentComplete : 'Betalning genomfört',
    Purchase : 'Betalning pågår',
    RemoveFromCart : 'Ta bort ifrån varukorg',
    Timeout: 'Timeout',
  }

  useEffect(() => {
    if(sessionId && storeState.selectedStore?.id) {
      fetchSession()
    }
  }, [sessionId, storeState.selectedStore?.id])

  return (
    <div className="session-info">
      {session && (
        <>
          <div className='session-info__card'>
            <div className='grid grid-flow-col auto-cols px-3 pt-3'>
              <div className='text-xl'>
                <div className='font-bold flex items-center'>#{session.id}{session.acr && <div className='ml-3'><AcrFlag acr={session.acr} width={18}/></div>}</div>
                <div>{dateStringToSessionDisplayDate(session.firstActivity)} - {dateStringToSessionDisplayDate(session.latestActivity)} ({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</div>
              </div>
              <div className='text-xl font-bold grid justify-end ml-4'>
                {displayPrice(session.totalPrice)} kr
              </div>
            </div>
            <div className='pl-3 pr-3'>{session.itemCount} varor</div>
            <div className='p-3'>
              <div className='text-lg font-bold pb-3'>Kundvarv händelser</div> 
              <ul>
                {session.events.map((event, i) => 
                  <li key={i} className={`grid pb-3 ${event.itemName ? 'grid-rows-2' : 'grid-rows-1'}`}>
                    <div>
                      <span>{dateStringToDisplayDate(event.createdAt).substring(11, 20)}</span>
                      <span> {eventTypeTranslation[event.eventType]}</span>
                    </div>
                    {(event.eventType === 'DoorAccessDenied') &&
                    <div className='truncate bold'>
                      Spärrad användare
                    </div>
                    }
                    {(event.eventType === 'AgeAccessDenied') &&
                    <div className='truncate bold'>
                      Spärrad användare p.g.a. ålderskrav
                    </div>
                    }
                    {(event.eventType === 'NotStammisAccessDenied') &&
                    <div className='truncate bold'>
                      Spärrad användare p.g.a. icke-stammis
                    </div>
                    }
                    {event.itemName && 
                    <div>
                      <span className='truncate bold' title={event.itemName}>{event.itemName}</span>
                      {event.itemWeightInGrams && <span className='truncate'> (Vikt: {event.itemWeightInGrams}g)</span>}
                    </div>
                    }
                  </li>)}
              </ul>
            </div>
          </div>
        </>
      )}
      {loading &&
      <div className='grid place-content-center h-72'>
        <SpinnerIcon />
      </div>
      }
      {!loading && error && 
      <div className='grid place-content-center h-72'>
        <p className='error text-center'>Någonting gick fel.</p>
      </div>
      }
    
    </div>
  )
}
