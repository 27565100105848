import React from 'react'
import { OverviewStatistics } from '../components/OverviewStatistics/OverviewStatistics'
import { useUserContext } from '../contexts/user-context'
import { isAdmin } from '../utils/userUtils'
import { useStoreContext } from '../contexts/store-context'

const Overview = () => {
  const { userState } = useUserContext()
  const { storeState } = useStoreContext()

  return (
    <div className='pt-5'>
      {(isAdmin(userState.user.roles) || storeState.availableStores?.length > 0)
        ? <OverviewStatistics />
        : <div className='text-center'>Du har inte tillgång till några butiker.</div>
      }
    </div>
  )
}

export default Overview