import React, { useEffect, useRef, useState } from "react"
import './Header.scss'
import { Link, useLocation, useNavigate } from "react-router-dom"
import Logout from '../Logout'
import { useUserContext } from '../../contexts/user-context'
import IcaLogo from "../Icons/IcaLogo"
import SelectStore from "../SelectStore"
import { isAdmin } from "../../utils/userUtils"
import { useStoreContext } from "../../contexts/store-context"
import { get } from "../../utils/http"
import StoreGroceryStock from "../../types/StoreGroceryStock"
import config from "../../config"

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const overviewRef = useRef<HTMLAnchorElement | null>(null)
  const liveFeedRef = useRef<HTMLAnchorElement | null>(null)
  const purchasesRef = useRef<HTMLAnchorElement | null>(null)
  const customerRef = useRef<HTMLAnchorElement | null>(null)
  const groceryFeedRef = useRef<HTMLAnchorElement | null>(null)
  const { userState } = useUserContext()
  const { storeState } = useStoreContext()
  const [displayGroceries, setDisplayGroceries] = useState(false)

  const activeStyle = (linkElement: React.MutableRefObject<HTMLAnchorElement | null>) => {
    if (linkElement?.current) {
      const linkHref = linkElement.current.href.replace(window.location.origin, '').replace(location.search, '')
      return location.pathname.includes(linkHref) ? 'active' : ''
    }
    return ''
  }

  const LogoutButton = () => {
    return (
      <div className="tooltip">
        <div className="tooltip-text">{userState.user.displayName}</div>
        <Logout />
      </div>
    )
  }

  const getTodayText = () => {
    const today = new Date()
    return `${today.getDate()}/${today.getMonth() + 1}`
  }

  useEffect(() => {
    async function setup() {
      const groceries = await get<Array<StoreGroceryStock>>(`${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock/${storeState.selectedStore?.id}`)
      if(groceries.length === 0 && location.pathname === '/groceries') {
        navigate('/overview')
      }
      setDisplayGroceries(groceries.length > 0)
    }

    if(storeState.selectedStore?.id) {
      setup()
    }
  }, [storeState.selectedStore?.id])

  return (
    <header className="header">
      <div className="header-nav grid lg:flex lg:justify-between md:grid sm:grid sm:grid-rows-2 content-center">
        <div className="grid grid-flow-col auto-cols-max justify-self-center lg:justify-self-start self-center p-2 lg:p-4">
          <Link to="/overview">
            <IcaLogo />
          </Link>
          <SelectStore />
        </div>
        {(isAdmin(userState.user.roles) || storeState.availableStores?.length > 0)
          ? <div className="grid grid-flow-col auto-cols-auto place-content-center p-2 lg:px-4 lg:py-4" >
            <Link ref={overviewRef} to="/overview" className={activeStyle(overviewRef)}>Idag {getTodayText()}</Link>
            <Link ref={liveFeedRef} to="/live-feed" className={activeStyle(liveFeedRef)}>Live</Link>
            <Link ref={purchasesRef} to={`/sessions`} className={activeStyle(purchasesRef)}>Kundvarv</Link>
            <Link ref={customerRef} to={`/customers`} className={activeStyle(customerRef)}>Kunder</Link>
            {displayGroceries &&
              <Link ref={groceryFeedRef} to={`/groceries`} className={activeStyle(groceryFeedRef)}>Sortiment</Link>
            }
            <span className="lg:hidden px-2"><LogoutButton /></span>
          </div>
          : <div></div>
        }
        <div className="hidden lg:grid justify-self-end self-center p-2 lg:order-last lg:px-4 lg:py-4">
          <LogoutButton />
        </div>
      </div>
    </header>
  )
}

export default Header