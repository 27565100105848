import React from 'react'
import './InputCheckbox.scss'

interface InputCheckboxProps {
  checked: boolean
  onCheckChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  id: string
  name?: string
  label?: string
}

const InputCheckbox = ({ checked, onCheckChanged, disabled = false, id, name, label }: InputCheckboxProps) => {
  
  return <div className='input-checkbox-container'>
    <input id={id} name={name} className='input-checkbox' type={'checkbox'} checked={checked} onChange={onCheckChanged} disabled={disabled}></input>
    {label &&
      <label htmlFor={id} className='ml-3'>{label}</label>
    }
  </div>
}

export default InputCheckbox