import  React, { useState } from 'react'
import InputDate from '../InputDate/InputDate'
import './InputDateRange.scss'

export interface InputDateRangeData {
 from: Date
 to: Date
}

export interface InputDateRangeProps {
  onFromChange: (from: Date) => void
  onToChange: (to: Date) => void
  defaultDateRange?: InputDateRangeData | null
}

export default function InputDateRange ({ onFromChange, onToChange, defaultDateRange = null }: InputDateRangeProps) {
  const getStartOfDay = (date: Date) => new Date(new Date(date).setHours(0, 0, 0, 0))
  const getEndOfDay = (date: Date) => new Date(new Date(date).setHours(23, 59, 59, 999))
  const [fromDate, setFromDate] = useState(defaultDateRange?.from ? getStartOfDay(defaultDateRange.from) : new Date())
  const [toDate, setToDate] = useState(defaultDateRange?.to ? getEndOfDay(defaultDateRange.to) : new Date())
  const [validationError, setValidationError] = useState(false)

  const updateDateRange = (date: Date, changed: string) => {
    let from = fromDate
    let to = toDate
    if (changed == 'from') {
      from = getStartOfDay(date)
    }
    else if (changed == 'to') {
      to = getEndOfDay(date)
    }
    
    // Validate
    if (from && to) {
      const fromTime = from.getTime()
      const toTime = to.getTime()
      if (fromTime > toTime) {
        setValidationError(true)
      } else {
        setValidationError(false)
      }
    }

    // Apply changes
    if (changed == 'from') {
      setFromDate(from)
      onFromChange(from)
    }
    else if (changed == 'to') {
      setToDate(to)
      onToChange(to)
    }
  }

  return (
    <div className={`input-range grid ${validationError ? '' : 'mb-4'}`}>
      <div className='grid grid-cols-2'>
        <div className='pr-3'>
          <label htmlFor='fromDate'>Startdatum</label>
          <InputDate onChange={(d) => updateDateRange(d, 'from')} value={fromDate} ariaLabel="fromDate" id='fromDate' name='fromDate' />
        </div>
        <div className='px-3'>
          <label htmlFor='toDate'>Slutdatum</label>
          <InputDate onChange={(d) => updateDateRange(d, 'to')} value={toDate} ariaLabel="toDate" id='toDate' name='toDate' />
        </div>
      </div>
      {validationError &&
        <div className='pl-3 input-range__error'>
          <span className='error'>Startdatum kan inte vara större än startdatum.</span>
        </div>
      }
    </div>
  )
}
