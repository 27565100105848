/* eslint-disable indent */
import  React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import InputDateRange from '../InputDateRange/InputDateRange'
import InputSelect, { SelectOption } from '../InputSelect/InputSelect'
import SidebarModal from '../SidebarModal/SidebarModal'
import "./SessionFilter.scss"
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import CloseIcon from '../Icons/CloseIcon'
import { getDefaultFilter, useFilterContext, Filter } from '../../contexts/filter-context'

export default function SessionFilter () {
  const location = useLocation()
  const { filterState, filterReducer } = useFilterContext()
  const [displayFilterModal, setDisplayFilterModal] = useState<boolean>(false)
  const filterButtonDefaultMsg = "Visa alla"
  const [filterButtonMsg, setFilterButtonMsg] = useState<string>(filterButtonDefaultMsg)

  const getSelectOptions = (start: number, end: number, unit?: string, step: number = 1, allowUnset: boolean = false, unsetText?: string): Array<SelectOption> => {
    const options = []
    if (allowUnset) {
      options.push({
        displayText: unsetText,
        value: '',
      } as SelectOption)
    }
    for (let i = start; i <= end; i += step) {
      options.push({
        displayText: unit ? `${i} ${unit}` : i,
        value: `${i}`,
      } as SelectOption)
    }
    return options
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.type) {
      case 'checkbox':
        filterReducer.setPropertyValue(event.target.name, event.target.checked)    
        break
      default:
        filterReducer.setPropertyValue(event.target.name, event.target.value)    
        break
    }
  }

  const handleSelectChange = (option: SelectOption, target: EventTarget) => {
    let value = null
    if (option.value !== '') {
      value = option.value
    }
    const targetName = (target as HTMLSelectElement).name
    filterReducer.setPropertyValue(targetName, value)
  }

  const handleModalReset = () => {
    const newFilter: Filter = getDefaultFilter()
    newFilter.storeId = filterState.filter.storeId
    newFilter.from = filterState.filter.from
    newFilter.to = filterState.filter.to
    filterReducer.setFilter(newFilter)
  }

  React.useEffect(() => {
    // Determine how many sidebar filters are active
    let activeFilterCount: number = 0
    const defaultFilter = getDefaultFilter()

    if (filterState.filter.paymentMissing != defaultFilter.paymentMissing) activeFilterCount++
    if (filterState.filter.removedItems != defaultFilter.removedItems) activeFilterCount++
    if (filterState.filter.accessDenied != defaultFilter.accessDenied) activeFilterCount++

    if (filterState.filter.minSessionDurationMinutes != defaultFilter.minSessionDurationMinutes
      || filterState.filter.maxSessionDurationMinutes != defaultFilter.maxSessionDurationMinutes)
        activeFilterCount++
    
    if (filterState.filter.minPriceKr != defaultFilter.minPriceKr
      || filterState.filter.maxPriceKr != defaultFilter.maxPriceKr)
       activeFilterCount++
    
    if (filterState.filter.minItemCount != defaultFilter.minItemCount
      || filterState.filter.maxItemCount != defaultFilter.maxItemCount)
      activeFilterCount++
      
    if (filterState.filter.minCustomerWeighedItemGrams != defaultFilter.minCustomerWeighedItemGrams
      || filterState.filter.maxCustomerWeighedItemGrams != defaultFilter.maxCustomerWeighedItemGrams)
      activeFilterCount++

    if (activeFilterCount == 0) {
      setFilterButtonMsg(filterButtonDefaultMsg)
    }
    else if (activeFilterCount == 1) {
      setFilterButtonMsg(`${activeFilterCount} vald`)
    }
    else {
      setFilterButtonMsg(`${activeFilterCount} valda`)
    }
  }, [filterState.filter])

  return (
    <div className='filter grid md:grid-cols-3'>
      <InputDateRange 
        onFromChange={(from) => filterReducer.setPropertyValue('from', from)} 
        onToChange={(to) => filterReducer.setPropertyValue('to', to)} 
        defaultDateRange={{ from: filterState.filter.from, to: filterState.filter.to }}/>
      {/* TODO: remove ifstatement when backend supports select filter on /customers*/}
      {location.pathname.includes('/sessions') ?
        <div className='mb-4'>
          <div className="grid justify-items-start content-center px-3">
            <label htmlFor='inputSelect'>Filter</label>
            <select
              defaultValue={''}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => setDisplayFilterModal(!displayFilterModal)}
              className="text-input filter-input-button select-none">
              <option value={''}>{filterButtonMsg}</option>
            </select>
          </div>
          <SidebarModal show={displayFilterModal}>
            <div className='flex'>
              <div className='filter-top-button flex items-center' onClick={() => setDisplayFilterModal(!displayFilterModal)}>
                <CloseIcon />Stäng
              </div>
              <span className='filter-top-button p-2 ml-auto' onClick={handleModalReset}>Rensa</span>
            </div>
            <div className='filter-title mt-5'>Filtrera</div>
            <div className='filter-subtitle mt-6'>Kundvarv</div>
            <hr className='my-4'/>
            <InputCheckbox label='Saknar betalning' id='filter-payment-missing' name='paymentMissing' checked={!!filterState.filter.paymentMissing} onCheckChanged={handleInputChange} />
            <hr className='my-4'/>
            <InputCheckbox label='Borttagning av vara' id='filter-removed-items' name='removedItems' checked={!!filterState.filter.removedItems} onCheckChanged={handleInputChange} />
            <hr className='my-4'/>
            <InputCheckbox label='Spärrad' id='filter-access-denied' name='accessDenied' checked={!!filterState.filter.accessDenied} onCheckChanged={handleInputChange} />
            <div className='filter-subtitle mt-6 mb-3'>Besökslängd</div>
            <div className='flex'>
              <InputSelect id='filter-min-duration'
                name='minSessionDurationMinutes'
                options={getSelectOptions(1, 60, 'minuter', 1, true, 'Min')}
                defaultFilterValue={filterState.filter.minSessionDurationMinutes}
                onInputSelectChange={handleSelectChange}
                className='flex-1 mr-2' />
              <InputSelect id='filter-max-duration'
                name='maxSessionDurationMinutes'
                options={getSelectOptions(1, 60, 'minuter', 1, true, 'Max')}
                defaultFilterValue={filterState.filter.maxSessionDurationMinutes}
                onInputSelectChange={handleSelectChange}
                className='flex-1 ml-2' />
            </div>
            <div className='filter-subtitle my-3'>Betalbelopp</div>
            <div className='flex'>
              <InputSelect id='filter-min-price'
                name='minPriceKr'
                options={getSelectOptions(10, 500, 'kr', 10, true, 'Min')}
                defaultFilterValue={filterState.filter.minPriceKr}
                onInputSelectChange={handleSelectChange}
                className='flex-1 mr-2' />
              <InputSelect id='filter-max-price' 
                name='maxPriceKr' 
                options={getSelectOptions(10, 500, 'kr', 10, true, 'Max')}
                defaultFilterValue={filterState.filter.maxPriceKr}
                onInputSelectChange={handleSelectChange}
                className='flex-1 ml-2' />
            </div>
            <div className='filter-subtitle my-3'>Antal varor</div>
            <div className='flex'>
              <InputSelect id='filter-min-items'
                name='minItemCount'
                options={getSelectOptions(1, 20, 'st', 1, true, 'Min')}
                defaultFilterValue={filterState.filter.minItemCount}
                onInputSelectChange={handleSelectChange}
                className='flex-1 mr-2' />
              <InputSelect id='filter-max-items' 
                name='maxItemCount' 
                options={getSelectOptions(1, 20, 'st', 1, true, 'Max')}
                defaultFilterValue={filterState.filter.maxItemCount}
                onInputSelectChange={handleSelectChange}
                className='flex-1 ml-2' />
            </div>
            <div className='filter-subtitle my-3'>Viktvaror</div>
            <div className='flex'>
              <InputSelect id='filter-min-weight'
                name='minCustomerWeighedItemGrams'
                options={getSelectOptions(500, 2000, 'g', 500, true, 'Min')}
                defaultFilterValue={filterState.filter.minCustomerWeighedItemGrams}
                onInputSelectChange={handleSelectChange}
                className='flex-1 mr-2' />
              <InputSelect id='filter-max-weight' 
                name='maxCustomerWeighedItemGrams' 
                options={getSelectOptions(500, 2000, 'g', 500, true, 'Max')}
                defaultFilterValue={filterState.filter.maxCustomerWeighedItemGrams}
                onInputSelectChange={handleSelectChange}
                className='flex-1 ml-2' />
            </div>
          </SidebarModal>
        </div>
        : <div></div>
      }
    </div>
  )
}
